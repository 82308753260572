import React from 'react';
import './home-page.css';
import Hero from "./hero";
import LastUpdates from "./last-updates";
import VideoViewer from "./video-viewer";

export default function HomePage() {
    return (
        <main>
            <Hero />
            {/*<LastUpdates />*/}
            {/*<VideoViewer />*/}
        </main>
    );
}